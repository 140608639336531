import React, { useContext, useEffect, useState } from 'react';
import PrivateRoute from '../PrivateRoute';
import { UserContext } from '../UserContext';
import { Link, navigate } from 'gatsby';
import axios from 'axios';
import displayErrors from '../utils';
import { useAlert } from 'react-alert';

const ListPage = () => {
  const alert = useAlert();
  const { logout, getSession } = useContext(UserContext);
  const [problems, setProblems] = useState(null);

  const deleteProblem = async (problemId) => {
    const { headers } = await getSession();

    axios
      .delete(`${process.env.GATSBY_API_URL}/problems/${problemId}`, {
        headers,
      })
      .then((res) => {
        if (window) {
          window.location.reload();
        }
      })
      .catch((err) => {
        displayErrors(err, alert);
      });
  };

  useEffect(() => {
    const getListData = async () => {
      const { headers } = await getSession();
      console.log(headers);

      axios
        .get(`${process.env.GATSBY_API_URL}/problems`, { headers })
        .then((res) => {
          const problemsData = res.data.message;
          problemsData.sort((a, b) => a.problemId - b.problemId);
          setProblems(problemsData);
        })
        .catch((err) => {
          displayErrors(err);
        });
    };
    getListData();
  }, []);

  return (
    <PrivateRoute>
      <button type="button" onClick={() => navigate('/edit')}>
        Create
      </button>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <button type="button" onClick={() => logout()}>
        Logout
      </button>
      <br />
      <br />
      <table>
        <tbody>
          {problems
            ? problems.map(({ problemId, title }) => (
                <tr key={problemId}>
                  <td>
                    <Link to={`/edit/?id=${problemId}`}>
                      {problemId} - {title}
                    </Link>
                  </td>
                  <td style={{ paddingLeft: '50px' }}>
                    [
                    <a href="#" onClick={() => deleteProblem(problemId)}>
                      DELETE
                    </a>
                    ]
                  </td>
                </tr>
              ))
            : 'Loading...'}
        </tbody>
      </table>
    </PrivateRoute>
  );
};

export default ListPage;
