import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import { UserContext } from './UserContext';

const PrivateRoute = (props) => {
  const { getSession } = useContext(UserContext);
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  getSession()
    .then((session) => {
      setIsLoggedIn(true);
    })
    .catch((err) => {
      setIsLoggedIn(false);
      navigate('/signIn');
    });

  return <div>{isLoggedIn ? props.children : ''}</div>;
};
export default PrivateRoute;
